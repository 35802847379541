import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-toggle-field',
  templateUrl: './dropdown-toggle-field.component.html',
  styleUrl: './dropdown-toggle-field.component.less'
})
export class DropdownToggleFieldComponent {
  @Input() isCollapsed: boolean = false;
  @Output() isCollapsedChange = new EventEmitter<boolean>();
  toggle(){
    this.isCollapsed=!this.isCollapsed;
    this.isCollapsedChange.emit(this.isCollapsed);
  }
}
