<div class="gravty-rich-text-field">
    <div
      class="gravty-rich-text-field__input"
      [style]="{
        width: width + widthUnit
      }"
      [title]="value && value.toString().length > 0 ? value : placeholder"
      [ngClass]="{
        'gravty-rich-text-field__input--readonly': readOnly
      }"
    >
    <div class="gravty-rich-text-field__input-prefix-text" [ngClass]="{ cursorIcon: iconClicked,'gravty-rich-text-field__input-prefix-text__required':required }" *ngIf="
    fieldTitle !== '' && fieldTitle !== undefined && fieldTitle !== null
  ">
      {{ fieldTitle }}:
  </div>
      <input
        class="gravty-rich-text-field__input-field"
        [placeholder]="placeholder"
        type="rich-text"
        [(ngModel)]="value"
        [readOnly]="true"
        [title]="value && value.toString().length > 0 ? value : placeholder"
        aria-label="rich rich-text field"
        [ngClass]="{'gravty-rich-text-field__input-field--pl-0':fieldTitle}"
        #InputField
      />
      <div
        class="gravty-rich-text-field__input-icon"
        [ngStyle]="{
          'background-image': readOnly
            ? 'url(assets/images/icons/view_document.png)' : 'url(assets/images/icons/editrichtext@3x.png)'
        }"
        [attr.aria-label]="readOnly ? 'preview': 'edit'"
        role="button"
        tabindex="0"
        (keyup.enter)="iconClick()"
        (click)="iconClick()"
      ></div>
    </div>
  </div>
  