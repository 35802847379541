import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SuperFilterConfig } from '../../interfaces/super-filter-config.interface';
import { DUMMY_SUPER_FILTER_DATA } from './dummy-super-filter.data';
import { SuperFilterUtil } from '../../utils/super-filter.util';

@Component({
  selector: 'app-super-filter-button',
  templateUrl: './super-filter-button.component.html',
  styleUrl: './super-filter-button.component.less',
})
export class SuperFilterButtonComponent {
  @Input() disabled: boolean = false;
  @Input() disableAccessibility = false;
  @Input('config') _superFilterConfig: SuperFilterConfig =
    DUMMY_SUPER_FILTER_DATA;

  @Output() filter: EventEmitter<SuperFilterConfig> =
    new EventEmitter<SuperFilterConfig>();

  get superFilterConfig(): SuperFilterConfig {
    return this._superFilterConfig;
  }

  set superFilterConfig(config: SuperFilterConfig) {
    this._superFilterConfig = config;
    this.filter.emit(this.superFilterConfig);
  }
  resetFilter(event: Event) {
    this.superFilterConfig = SuperFilterUtil.resetFilters(
      this.superFilterConfig
    );
    event.stopPropagation();
  }
  get isFilterApplied(): boolean {
    return SuperFilterUtil.isFilterApplied(this.superFilterConfig);
  }
}
