<div class="richtext-editor">
  <div class="richtext-editor-header">
    <div class="richtext-editor-header-title">Rich Text Formatting</div>
  </div>
  <div class="richtext-editor-body">
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu
        [editor]="editor"
        [toolbar]="toolbar"
        [disabled]="disabled || readonly"
        [ngStyle]="{
          pointerEvents: isTextEditorDisabled ? 'none' : 'auto'
        }"
      >
      </ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        [(ngModel)]="_editorValue"
        [disabled]="disabled"
        (click)="enableEdit($event)"
        [ngStyle]="{
          opacity: isTextEditorDisabled ? '0.3' : '1'
        }"
        [ngClass]="{ 'NgxEditor__Wrapper__text-editor-read-only': readonly }"
        *ngIf="!showHtmlEditor"
      ></ngx-editor>
      <textarea
        class="htmlEditor"
        *ngIf="showHtmlEditor"
        [(ngModel)]="_editorValue"
        [disabled]="disabled || readonly"
      >
      </textarea>
    </div>
  </div>
  <div class="richtext-editor-footer">
    <app-button
      *ngIf="!readonly"
      [displayText]="'Cancel'"
      [width]="200"
      [height]="48"
      (clicked)="closeModel()"
      [showBorder]="false"
    ></app-button>
    <app-button
      *ngIf="!readonly"
      [disabled]="value === '' || value === undefined || value === null"
      [displayText]="'Save'"
      [width]="200"
      [height]="48"
      (clicked)="saveRichpopup()"
      [type]="'primary'"
      [showBorder]="false"
    ></app-button>
    <app-button
      *ngIf="readonly"
      [displayText]="'Close'"
      [width]="200"
      [height]="48"
      (clicked)="closeModel()"
      [showBorder]="false"
    ></app-button>
  </div>
</div>
