import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rich-text-input-field',
  templateUrl: './rich-text-input-field.component.html',
  styleUrl: './rich-text-input-field.component.less'
})
export class RichTextInputFieldComponent {
  @Input() fieldTitle?: string;
  @Input() required:boolean=false;
  @Output() iconClicked = new EventEmitter();
  @Input() width: number = 100;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = '%';
  @Input() placeholder: string = '';
  @Input() value: any = '';
  @Input() readOnly: boolean = false;
  ngOnInit(): void {
  }
  iconClick() {
  this.iconClicked.emit({});
  }
}
