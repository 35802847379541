import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './lib/components/button/button.component';
import { CustomTextFieldComponent } from './lib/components/custom-text-field/custom-text-field.component';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from './lib/components/search-input/search-input.component';
import { OtpFieldComponent } from './lib/components/otp-field/otp-field.component';
import { PaginationComponent } from './lib/components/pagination/pagination.component';
import { SpacerComponent } from './lib/components/spacer/spacer.component';
import { CustomTextAreaFieldComponent } from './lib/components/custom-text-area-field/custom-text-area-field.component';
import { CustomCheckBoxComponent } from './lib/components/custom-check-box/custom-check-box.component';
import { CustomSelectDropdownComponent } from './lib/components/custom-select-dropdown/custom-select-dropdown.component';
import { ToggleFieldComponent } from './lib/components/toggle-field/toggle-field.component';
import { PlaceholderComponent } from './lib/components/placeholder/placeholder.component';
import { InlineLoaderComponent } from './lib/components/inline-loader/inline-loader.component';
import { SideStepperComponent } from './lib/components/side-stepper/side-stepper.component';
import { SearchSelectComponent } from './lib/components/search-select/search-select.component';
import { GlobalLoaderComponent } from './lib/components/global-loader/global-loader.component';

import { MultiSelectDropdownComponent } from './lib/components/multi-select-dropdown/multi-select-dropdown.component';
import { IconButtonComponent } from './lib/components/icon-button/icon-button.component';
import { InfoTooltipComponent } from './lib/components/info-tooltip/info-tooltip.component';
import { ToolTipDirective } from './lib/directives/tool-tip.directive';
import { UploadImageSectionComponent } from './lib/components/upload-image-section/upload-image-section.component';
import { DatePickerComponent } from './lib/components/date-picker/date-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from './lib/components/date-range-picker/date-range-picker.component';
import { DateInputFieldComponent } from './lib/components/date-input-field/date-input-field.component';
import { TransferSelectComponent } from './lib/components/transfer-select/transfer-select.component';
import { SelectFacetsFieldComponent } from './lib/components/select-facets-field/select-facets-field.component';
import { FilterPipe } from '../shared/pipes/filter.pipe';
import { AlertDialogComponent } from './lib/components/alert-dialog/alert-dialog.component';
import { GlobalErrorComponent } from './lib/components/global-error/global-error.component';
import { SplittedTextSelectFieldComponent } from './lib/components/splitted-text-select-field/splitted-text-select-field.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SuperFilterButtonComponent } from './lib/components/super-filter-button/super-filter-button.component';
import { SuperFilterDirective } from './lib/directives/super-filter.directive';
import { SuperFilterComponent } from './lib/filters/super-filter/super-filter.component';
import { UploadDocumentComponent } from './lib/components/upload-document/upload-document.component';
import { GoogleMapComponent } from './lib/components/google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TimeInputFieldComponent } from './lib/components/time-input-field/time-input-field.component';
import { AddCommaPipe } from './lib/pipes/add-comma.pipe';
import { DropdownToggleFieldComponent } from './lib/components/dropdown-toggle-field/dropdown-toggle-field.component';
import { RichtextEditorComponent } from './lib/components/richtext-editor/richtext-editor.component';
import { NgxEditorModule } from 'ngx-editor';
import { RichTextInputFieldComponent } from './lib/components/rich-text-input-field/rich-text-input-field.component';
@NgModule({
  declarations: [
    ButtonComponent,
    CustomTextFieldComponent,
    SearchInputComponent,
    OtpFieldComponent,
    PaginationComponent,
    SpacerComponent,
    CustomTextAreaFieldComponent,
    CustomCheckBoxComponent,
    CustomSelectDropdownComponent,
    ToggleFieldComponent,
    PlaceholderComponent,
    InlineLoaderComponent,
    SideStepperComponent,
    SearchSelectComponent,
    GlobalLoaderComponent,
    MultiSelectDropdownComponent,
    IconButtonComponent,
    InfoTooltipComponent,
    ToolTipDirective,
    UploadImageSectionComponent,
    DatePickerComponent,
    DateRangePickerComponent,
    DateInputFieldComponent,
    TransferSelectComponent,
    SelectFacetsFieldComponent,
    FilterPipe,
    AlertDialogComponent,
    GlobalErrorComponent,
    SplittedTextSelectFieldComponent,
    SuperFilterButtonComponent,
    SuperFilterDirective,
    SuperFilterComponent,
    UploadDocumentComponent,
    GoogleMapComponent,
    TimeInputFieldComponent,
    AddCommaPipe,
    DropdownToggleFieldComponent,
    RichtextEditorComponent,
    RichTextInputFieldComponent
    ],
  imports: [
    CommonModule,
    FormsModule,
    NgxDaterangepickerMd.forRoot(),
    TranslateModule.forRoot(),
    GoogleMapsModule,
    NgxEditorModule,

  ],
  exports: [
    ButtonComponent,
    CustomTextFieldComponent,
    SearchInputComponent,
    OtpFieldComponent,
    PaginationComponent,
    SpacerComponent,
    CustomTextAreaFieldComponent,
    CustomCheckBoxComponent,
    CustomSelectDropdownComponent,
    ToggleFieldComponent,
    PlaceholderComponent,
    InlineLoaderComponent,
    SideStepperComponent,
    SearchSelectComponent,
    MultiSelectDropdownComponent,
    IconButtonComponent,
    InfoTooltipComponent,
    ToolTipDirective,
    UploadImageSectionComponent,
    DateInputFieldComponent,
    SelectFacetsFieldComponent,
    SplittedTextSelectFieldComponent,
    GlobalErrorComponent,
    SuperFilterButtonComponent,
    SuperFilterComponent,
    UploadDocumentComponent,
    GoogleMapComponent,
    TimeInputFieldComponent,
    AddCommaPipe,
    DropdownToggleFieldComponent,
    RichtextEditorComponent,
    RichTextInputFieldComponent
  ],
  providers: [TranslateService]
})
export class LibraryModule { }
