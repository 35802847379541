import { Component, EventEmitter, Input, Output, OnDestroy, OnInit, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {  Editor, Toolbar } from 'ngx-editor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-richtext-editor',
  templateUrl: './richtext-editor.component.html',
  styleUrl: './richtext-editor.component.less'
})
export class RichtextEditorComponent {
  editor: any;
  value: string = '';
  @Output() valueChange = new EventEmitter();
  @Input() errorMessage: string = '';
  @Input() disabled: boolean = false;
  @Input() placeholder: string = '';
  readonly: boolean = false;
  @Input() required: boolean = false;
  showHtmlEditor: boolean = false;
  showPlaceholder: boolean = true;
  toolbar: Toolbar = [
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['bold'],
    ['italic'],
    ['underline'],
    ['strike'],
    ['bullet_list'],
    ['ordered_list'],
    ['align_left'],
    ['align_center'],
    ['align_right'],
    ['align_justify'],
    ['image'],
    ['blockquote'],
    ['link'],
  ];
  editorValue: string = '';
  public get _editorValue(): string {
    return this.editorValue;
  }
  public set _editorValue(v: string) {
    this.editorValue = v;
    this.onChange(v);
  }
  toggleCodeView() {
    this.showHtmlEditor = !this.showHtmlEditor;
    this.showPlaceholder = !this.showHtmlEditor;
  }
  ngOnDestroy(): void {
    if(this.editor)
    this.editor.destroy();
  }
  onChange(val: string) {
    if (!this.showPlaceholder) {
      if (val == '<p></p>') {
        val = '';
      }
      this.value = val;
      this.valueChange.emit(val);
      this.editorValue = val;
    }
  }
  constructor(
    private dialogRef: MatDialogRef<RichtextEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    data:any
  ) {
    this.value=data.value;
    this.readonly=data.isReadOnly;
  }
  
  ngOnInit() {
    this.editor = new Editor();
    this.editorValue =
      this.value?.length > 0 && this.value != '<p></p>'
        ? this.value
        : this.placeholder;
    document.addEventListener(
      'click',
      (event: any) => {
        const parentClass = event.target?.parentNode?.className ?? '';
        const childClass = event.target?.className ?? '';
        try {
          if (
            !childClass.includes('NgxEditor') &&
            !parentClass.includes('NgxEditor') &&
            !parentClass.includes('code_icon') &&
            !childClass.includes('code_icon')   
          ) {
            const isEmptyContent =
              this.editorValue.trim() === '' || this.editorValue === '<p></p>';
            if (!isEmptyContent) {
              return;
            }
            if (this.value?.length > 0 && this.value != '<p></p>') {
              this.editorValue = this.value;
            } else {
              this.showPlaceholder = true;
              this.editorValue = this.placeholder;
            }
          }
        } catch (error) {
        }
      },
      true
    );
  }
  closeModel(){
this.dialogRef.close();
  }
  saveRichpopup(){
    this.dialogRef.close({
     value:this.value
    });
  }
  enableEdit(event: Event) {
    if (this.disabled || this.readonly) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    this.showPlaceholder = false;
    this.editorValue = this.value;
    event.stopPropagation();
    event.preventDefault();
  }
  get isTextEditorDisabled(): boolean {
    return false;
  }
}
