<div
  class="dropdown-toggle-field"
  [ngClass]="{ 'dropdown-toggle-field--black': isCollapsed }"
  (click)="toggle()"
>
  <img
    [src]="
      isCollapsed
        ? 'assets/images/icons/expand_down@3x.png'
        : 'assets/images/icons/expand_up@3x.png'
    "
    alt="expand Img"
  />
</div>
